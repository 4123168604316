import React from 'react';
import './Misc.css';
/*import TradingViewWidget from './TradingViewWidget';*/

function Misc() {
  return (
    <div className='misc'>
      <div className='misc__top'>
        <h2>Misc</h2>
      </div>
      <div className='misc__center'>
        <iframe
          className='misc__item'
          title='ArkInvest Dashboard'
          width='1024'
          height='612'
          src='https://app.powerbi.com/view?r=eyJrIjoiZDg1NmJlNjItY2VjYS00OWEwLTkzMTQtMTZmMzVjZGVjNWYzIiwidCI6IjMxNzFiZWQ3LThlNGYtNDkxOS05NTdhLTAyYmNmZTEyNzViZCIsImMiOjl9'
          frameborder='0'
          allowFullScreen='true'
        ></iframe>
        <div className='misc__item__desc'>
          Fetches holdings from ARK Invest ETFs and tracks changes between the
          dataset.
        </div>
        {/*  <div className='misc__item'>
          <TradingViewWidget />
        </div> --> */}
      </div>
    </div>
  );
}

export default Misc;
