import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyAEhdh0FRJWk9P-1yubx2rl8cyeQ4D23WI',
  authDomain: 'mawagra-249d7.firebaseapp.com',
  databaseURL: 'https://mawagra-249d7.firebaseio.com',
  projectId: 'mawagra-249d7',
  storageBucket: 'mawagra-249d7.appspot.com',
  messagingSenderId: '1028325470373',
  appId: '1:1028325470373:web:62678d7c9698a01f39dd4c',
  measurementId: 'G-B5DFFENCYV',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider };
export default db;
