import React from 'react';
import './Portfolio.css';

function Portfolio() {
  return (
    <div className='portfolio'>
      <div className='portfolio__top'>
        <h2>Portfolio</h2>
      </div>
      <div className='portfolio__center'></div>
    </div>
  );
}

export default Portfolio;
