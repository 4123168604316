import React from 'react';
import './Login.css';
import { auth, provider } from './firebase';
import Button from '@material-ui/core/Button';
import { actionTypes } from '../reducer';
import { useStateValue } from '../StateProvider';
import logo from '../img/mawagra_logo_alt.PNG';
import { Redirect } from 'react-router-dom';

function Login() {
  const [state, dispatch] = useStateValue();
  const [{ user }] = useStateValue();

  const signIn = (e) => {
    e.preventDefault();
    auth
      .signInWithPopup(provider)
      .then((result) => {
        dispatch({
          type: actionTypes.SET_USER,
          user: result.user,
        });
      })
      .catch((error) => alert(error.message));
  };

  if (user) {
    return <Redirect to='/' />;
  }

  return (
    <div className='login'>
      <div className='login__logo'>
        <img src={logo} alt='' />
      </div>
      <Button type='submit' onClick={signIn}>
        Sign In
      </Button>
    </div>
  );
}

export default Login;
