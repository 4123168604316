import React, { useState } from 'react';
import './CommentSender.css';
import { useStateValue } from '../StateProvider';
import { Avatar, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from '@material-ui/core/styles';
import db from './firebase';
import firebase from 'firebase';

const useStyles = makeStyles({
  root: {
    background: '#171790',
    color: 'white',
    '&:hover': {
      background: '#171790',
    },
  },
});

function CommentSender({ postId }) {
  const [input, setInput] = useState('');
  const [{ user }] = useStateValue();
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('posts').doc(postId).collection('comments').add({
      message: input,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      profilePic: user.photoURL,
      user: user.displayName,
    });

    setInput('');
  };

  return (
    <div className='commentSender'>
      <div className='commentSender__top'>
        <Avatar src={user.photoURL} />
        <form onSubmit={handleSubmit}>
          <input
            name='comment'
            id='comment'
            className='commentSender__input'
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder={'Write a comment...'}
          />
          <IconButton
            type='submit'
            classes={{
              root: classes.root,
            }}
          >
            <SendIcon></SendIcon>
          </IconButton>
        </form>
      </div>
    </div>
  );
}

export default CommentSender;
