import React, { useState } from 'react';
import './Header.css';
import HomeIcon from '@material-ui/icons/Home';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import { Link } from 'react-router-dom';
import { Avatar, IconButton } from '@material-ui/core';
import { useStateValue } from '../StateProvider';

function Header() {
  const [activeComponent, setActiveComponent] = useState('');
  const [{ user }] = useStateValue();

  const toggleComponent = (component) => {
    if (component) {
      setActiveComponent(component);
    }
  };

  return (
    <div className='header'>
      <div className='header__center'>
        <Link to='/'>
          <div
            className={`header__option header__option${
              activeComponent === 'Home' && '--active'
            }`}
            onClick={() => toggleComponent('Home')}
          >
            <HomeIcon fontSize='large' />
          </div>
        </Link>
        <Link to='/about'>
          <div
            className={`header__option header__option${
              activeComponent === 'About' && '--active'
            }`}
            onClick={() => toggleComponent('About')}
          >
            <InfoOutlinedIcon fontSize='large' />
          </div>
        </Link>
        <Link to='/portfolio'>
          <div
            className={`header__option header__option${
              activeComponent === 'Portfolio' && '--active'
            }`}
            onClick={() => toggleComponent('Portfolio')}
          >
            <WorkOutlineOutlinedIcon fontSize='large' />
          </div>
        </Link>
        <Link to='/misc'>
          <div
            className={`header__option header__option${
              activeComponent === 'Misc' && '--active'
            }`}
            onClick={() => toggleComponent('Misc')}
          >
            <AppsOutlinedIcon fontSize='large' />
          </div>
        </Link>
        <Link to='/contact'>
          <div
            className={`header__option header__option${
              activeComponent === 'Contact' && '--active'
            }`}
            onClick={() => toggleComponent('Contact')}
          >
            <ContactMailOutlinedIcon fontSize='large' />
          </div>
        </Link>
      </div>
      <div className='header__right'>
        <div className='header__info'>
          <Link to='/login'>
            <IconButton>
              <Avatar src={user ? user.photoURL : ''} />
            </IconButton>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
