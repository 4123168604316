import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './components/About';
import Misc from './components/Misc';
import Work from './components/Portfolio';
import Contact from './components/Contact';
import Login from './components/Login';
import Feed from './components/Feed';

function App() {
  return (
    <div className='app'>
      <Router>
        <Header />
        <div className='app__body'>
          <Switch>
            <Route exact path='/' component={Feed} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/about' component={About} />
            <Route exact path='/misc' component={Misc} />
            <Route exact path='/portfolio' component={Work} />
            <Route exact path='/contact' component={Contact} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
