import React from 'react';
import './Footer.css';
import CopyrightIcon from '@material-ui/icons/Copyright';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';

function Footer() {
  return (
    <div className='footer'>
      <div className='footer__left'>
        <CopyrightIcon />{' '}
        <span className='footer__copyright__text'>
          2020 - MaWaGra Solutions
        </span>
      </div>
      <div className='footer__center'>
        <div className='footer__option'>
          <LinkedInIcon
            fontSize='large'
            onClick={(event) =>
              (window.location.href = 'https://www.linkedin.com/in/martinwg/')
            }
          />
        </div>
        <div className='footer__option'>
          <TwitterIcon
            fontSize='large'
            onClick={(event) =>
              (window.location.href = 'https://twitter.com/mawagras')
            }
          />
        </div>
        <div className='footer__option'>
          <YouTubeIcon
            fontSize='large'
            onClick={(event) =>
              (window.location.href =
                'https://www.youtube.com/channel/UCmPPjJTaE1R9DYzV5K1BPEg')
            }
          />
        </div>
        <div className='footer__option'>
          <InstagramIcon
            fontSize='large'
            onClick={(event) =>
              (window.location.href = 'https://www.instagram.com/mawagra/')
            }
          />
        </div>
      </div>
      <div className='footer__right'></div>
    </div>
  );
}

export default Footer;
