import React from 'react';
import './Contact.css';
import { useFormik } from 'formik';
import { Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from '@material-ui/core/styles';
import db from './firebase';
import firebase from 'firebase';

const useStyles = makeStyles({
  root: {
    background: '#171790',
    color: 'white',
    '&:hover': {
      background: '#171790',
    },
  },
});

function Contact() {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await db.collection('contacts').add({
          name: values.name,
          email: values.email,
          message: values.message,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } catch (error) {
        console.log(error);
      }

      setSubmitting(false);
      resetForm();
    },
  });

  return (
    <div className='contact'>
      <div className='contact__top'>
        <h2>Contact</h2>
      </div>
      <div className='contact__center'>
        <p className='contact__intro'>
          To contact me send me a message or use the social media links below
        </p>
        <div className='contact__form'>
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor='name'>Name</label>
            <input
              type='text'
              id='name'
              name='name'
              value={formik.values.name || ''}
              onChange={formik.handleChange}
              values={formik.values.name}
            />
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              id='email'
              name='email'
              value={formik.values.email || ''}
              onChange={formik.handleChange}
              values={formik.values.email}
            />
            <label htmlFor='message'>Message</label>
            <textarea
              id='message'
              name='message'
              rows='8'
              cols='50'
              style={{ resize: 'none' }}
              value={formik.values.message || ''}
              onChange={formik.handleChange}
              values={formik.values.message}
            />
            <div className='form__button'>
              <Button
                type='submit'
                variant='contained'
                classes={{
                  root: classes.root,
                }}
                endIcon={<SendIcon></SendIcon>}
              >
                Send
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Contact;
