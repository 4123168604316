import React from 'react';
import './Comment.css';
import { Avatar, IconButton } from '@material-ui/core';
import { useStateValue } from '../StateProvider';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import db from './firebase';
import moment from 'moment';

function Comment({ postId, commentId, profilePic, user, timestamp, message }) {
  const [{ user: isAuthenticated }] = useStateValue();

  function handleDelete() {
    db.collection('posts')
      .doc(postId)
      .collection('comments')
      .doc(commentId)
      .delete()
      .then(function () {
        console.log('Document successfully deleted.');
      })
      .catch(function (error) {
        console.error('Error removing document: ', error);
      });
  }

  return (
    <div className='comment'>
      <div className='comment__body'>
        <div className='comment__avatar'>
          <Avatar src={profilePic} />
        </div>
        <p className='comment__message'>
          <span className='comment__name'>{user}</span>
          {message}
          <span className='comment__timestamp'>
            {moment(new Date(timestamp?.toDate()).toLocaleString()).fromNow()}
          </span>
        </p>

        {(user === isAuthenticated?.displayName ||
          isAuthenticated?.displayName === 'Martin Waara-Grape') && (
          <div className='comment__delete'>
            <IconButton onClick={() => handleDelete()}>
              <HighlightOffOutlinedIcon />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default Comment;
