import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './Post.css';
import { Avatar, IconButton } from '@material-ui/core';
import ChatBubbleOutlinedIcon from '@material-ui/icons/ChatBubbleOutline';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import CommentSender from './CommentSender';
import { useStateValue } from '../StateProvider';
import db from './firebase';
import Comment from './Comment';
import { Redirect } from 'react-router-dom';

function Post({ postId, profilePic, image, media, user, timestamp, message }) {
  const [showCommentSender, toggleCommentSender] = useState(false);
  const [showComments, toggleComments] = useState(false);
  const [{ user: isAuthenticated }] = useStateValue();
  const [adminUser, setAdminUser] = useState([]);
  const [comments, setComments] = useState([]);
  const [commentClicked, toggleCommentClicked] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await db
        .collection('posts')
        .doc(postId)
        .collection('comments')
        .orderBy('timestamp', 'asc')
        .onSnapshot((snapshot) =>
          setComments(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          )
        );
    }
    fetchData();
  }, [postId]);

  useEffect(() => {
    async function fetchAdminUser() {
      await db
        .collection('users')
        .where('role', '==', 'admin')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            setAdminUser(querySnapshot.docs[0].id);
          }
        });
    }
    fetchAdminUser();
  }, []);

  if (!isAuthenticated && commentClicked) {
    return <Redirect to='/login' />;
  }

  function handleDelete() {
    db.collection('posts')
      .doc(postId)
      .delete()
      .then(function () {
        console.log('Document successfully deleted.');
      })
      .catch(function (error) {
        console.error('Error removing document: ', error);
      });
  }

  function handleCommentClick() {
    toggleCommentSender(!showCommentSender);
    toggleCommentClicked(!commentClicked);
    toggleComments(!showComments);
  }

  return (
    <div className='post'>
      <div className='post__top'>
        <Avatar src={profilePic} className='post__avatar' />
        <div className='post__topInfo'>
          <h3>{user}</h3>
          <p>{new Date(timestamp?.toDate()).toLocaleString()}</p>
        </div>
        {isAuthenticated && isAuthenticated.uid === adminUser && (
          <div className='post__topInfo__right'>
            <IconButton onClick={() => handleDelete()}>
              <HighlightOffOutlinedIcon />
            </IconButton>
          </div>
        )}
      </div>

      <div className='post__bottom'>
        <p>{message}</p>
      </div>

      <div className='post__image'>
        <img src={image} alt='' />
      </div>
      {media && (
        <div className='post__media'>
          <ReactPlayer
            className='react-player'
            controls
            url={media}
            width='100%'
            height='100%'
          />
        </div>
      )}
      <div className='post__comment__count'>
        {comments.length > 0
          ? comments.length === 1
            ? `${comments.length} Comment`
            : `${comments.length} Comments`
          : ''}
      </div>

      <div className='post__options'>
        <div className='post__option' onClick={() => handleCommentClick()}>
          <ChatBubbleOutlinedIcon />
          <p>Comment</p>
        </div>
      </div>
      {comments.length !== 0 && (
        <div
          className='post__option'
          onClick={() => toggleComments(!showComments)}
        >
          {showComments ? (
            <ExpandLessOutlinedIcon />
          ) : (
            <ExpandMoreOutlinedIcon />
          )}
        </div>
      )}

      {showComments && comments.length !== 0 && (
        <div className='post__comments'>
          {comments.map((comment) => (
            <Comment
              key={comment.id}
              commentId={comment.id}
              postId={postId}
              profilePic={comment.data.profilePic}
              message={comment.data.message}
              timestamp={comment.data.timestamp}
              user={comment.data.user}
            />
          ))}
        </div>
      )}

      {showCommentSender && isAuthenticated && (
        <div className='post__comment'>
          <CommentSender postId={postId} />
        </div>
      )}
    </div>
  );
}

export default Post;
