import React, { useState, useEffect } from 'react';
import './Feed.css';
import MessageSender from './MessageSender';
import Post from './Post';
import db from './firebase';
import { useStateValue } from '../StateProvider';
/*import TwitterWidget from './TwitterWidget';*/

function Feed() {
  const [posts, setPosts] = useState([]);
  const [adminUser, setAdminUser] = useState([]);
  const [{ user }] = useStateValue();

  useEffect(() => {
    db.collection('posts')
      .orderBy('timestamp', 'desc')
      .onSnapshot((snapshot) =>
        setPosts(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })))
      );
  }, []);

  useEffect(() => {
    async function fetchAdminUser() {
      await db
        .collection('users')
        .where('role', '==', 'admin')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            setAdminUser(querySnapshot.docs[0].id);
          }
        });
    }
    fetchAdminUser();
  }, []);

  return (
    <div className='feed'>
      <div className='sidebar__left'>{/*<TwitterWidget />*/}</div>
      <div className='post__feed'>
        {user && user.uid === adminUser && <MessageSender />}
        {posts.map((post) => (
          <Post
            key={post.id}
            postId={post.id}
            profilePic={post.data.profilePic}
            message={post.data.message}
            timestamp={post.data.timestamp}
            user={post.data.user}
            image={post.data.image}
            media={post.data.media}
          />
        ))}
      </div>
      <div className='sidebar__right'>{/*<TwitterWidget />*/}</div>
    </div>
  );
}

export default Feed;
