import React from 'react';
import './About.css';
import profilePic from '../img/surfbild_mini.jpg';
import { Avatar } from '@material-ui/core';

function About() {
  return (
    <div className='about'>
      <div className='about__top'>
        <h2>About</h2>
      </div>
      <div className='about__center'>
        <p className='about__center'>
          I'm a Freelance Business Intelligence consultant based in Stockholm,
          Sweden. More coming soon...
        </p>
      </div>
    </div>
  );
}

export default About;
